import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500;
`;
export const Modal = styled.div`
  width: 30vw;
  height: 15rem;
  background: rgb(25, 69, 71);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 10px;
  padding-bottom: 1rem;

  @media screen and (max-width: 900px) {
    width: 80vw;
    height: 30vh;
    max-height: 150px;
  }
`;
export const Text = styled.p`
  color: #f1f1f1;
  font-size: 1.5rem;
  text-align: center;
  font-weight: bold;
`;
export const Buttons = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;

  .copy {
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    color: #f1f1f1;
    &:hover {
      color: #4fd8e0;
    }
  }
`;
