import React from 'react';

import { Wrapper, Modal, Text, Buttons } from './share.styles';

import PropTypes from 'prop-types';
import { MdContentCopy } from 'react-icons/md';

import {
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from 'react-share';
import messageBanner from './messageBanner';

export default function SharePost({ url, closing }) {
  const close = (e) => {
    if (e.target === e.currentTarget) {
      closing();
    }
  };
  const saveTClipboard = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(url);
    messageBanner({
      message: `Link copied to clipboard 😍`,
      status: 'success',
    });
    setTimeout(() => {
      closing();
    }, 2500);
  };
  return (
    <Wrapper onClick={(e) => close(e)}>
      <Modal>
        <Text>Share this post</Text>
        <Buttons>
          <FacebookShareButton url={url}>
            <FacebookIcon size={48} round />
          </FacebookShareButton>
          <TwitterShareButton url={url}>
            <TwitterIcon size={48} round />
          </TwitterShareButton>
          <TelegramShareButton url={url}>
            <TelegramIcon size={48} round />
          </TelegramShareButton>
          <WhatsappShareButton url={url}>
            <WhatsappIcon size={48} round />
          </WhatsappShareButton>
          <MdContentCopy
            onClick={saveTClipboard}
            className="copy"
            size={40}
            color="#f1f1f1"
          />
        </Buttons>
      </Modal>
    </Wrapper>
  );
}

SharePost.propTypes = {
  url: PropTypes.string,
  closing: PropTypes.func,
};
